import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { type GetUserQuery, SubscribtionPlan, type SubscribtionType } from '@/gql/requests'

export type User = {
   id: number
   email: string
   name?: string | null
   avatar?: string | null
   subscription: {
      active: boolean
      trial: boolean
      trialUsed: boolean
      endDate?: Date
      autoPayment: boolean
      plan: SubscribtionPlan
      lastPaymentPlan?: string | null
      planType?: SubscribtionType | null
   }
   card?: {
      id: number
      last4: string
      expMonth: number
      expYear: number
   }
   betDiaryUrl?: string
   discordProfile?: {
      avatar?: string | null
   }
   telegramProfile?: {
      avatarUrl?: string | null
      inviteLink?: string | null
   }
   onboarding: {
      collapsed: boolean
      register: {
         onboarding: boolean
         promoMatch: boolean
         history: boolean
         card: boolean
      }
      subscribtion: {
         onboarding: boolean
         looners: boolean
         education: boolean
         betDiary: boolean
      }
   }
   progressBar: {
      planDaysPercentComplited: number
      month: number
      freeDays: boolean
      planStart?: Date
      planEnd?: Date
      isErrorPayment: boolean
   }
   usedFreeDays: {
      telegramGroup?: boolean | null
      telegramChannel?: boolean | null
      discord?: boolean | null
      telegramFreeChannel?: boolean | null
   }
   telegramData: {
      ru: {
         channel: {
            avatar: string
         }
      }
      en: {
         channel: {
            avatar: string
         }
      }
      all: {
         channel: {
            avatar: string
         }
         group: {
            avatar: string
         }
      }
   }
   tgProfile?: {
      id: number
      lastName?: string | null
      username?: string | null
      firstName: string
   } | null
   bonuses: {
      groupId: number
      created: Date
   }[]
}

// userSub - есть подписка
// userUnSub - нет подписки
// userNew - новый не юзавший триал
// userUnReg - не зареган

export const useUserStore = defineStore('user', () => {
   const loading = ref(true)
   const user = ref<User>()

   const userSub = computed(() => {
      return !!user.value && user.value.subscription.active
   })

   const userUnSub = computed(() => {
      return !!user.value && !user.value.subscription.active && user.value.subscription.trialUsed
   })

   const userNew = computed(() => {
      return !!user.value && !user.value.subscription.active && !user.value.subscription.trialUsed
   })

   const userUnReg = computed(() => {
      return !loading.value && !user.value
   })

   const userTrial = computed(() => {
      return !!user.value && user.value.subscription.trial
   })

   const marketingObjectLink = computed(() => {
      if (userUnReg.value) {
         return { name: 'signout' }
      }

      if (userNew.value) {
         return { name: 'subscription', query: { tariff: 'pro' } }
      }

      if (userUnSub.value) {
         return { name: 'subscription', query: { tariff: user.value?.subscription.lastPaymentPlan?.toLowerCase() ?? 'pro' } }
      }

      return { name: 'subscription' }
   })

   const setUser = (userData: GetUserQuery['user']) => {
      const body: User = {
         id: userData.id,
         name: userData.name,
         email: userData.email,
         avatar: userData.avatarUrl,
         subscription: {
            active: userData.subscription.active,
            trial: userData.subscription.trial,
            trialUsed: userData.subscription.trialUsed,
            endDate: userData.subscription.endDate,
            autoPayment: userData.subscription.autoPayment,
            plan: userData.subscription.plan,
            lastPaymentPlan: userData.subscription.lastPaymentPlan,
            planType: userData.subscription.planType
         },
         betDiaryUrl: userData.betDiaryUrl ?? undefined,
         discordProfile: userData.discordProfile ?? undefined,
         telegramProfile: userData.telegramProfile ?? undefined,
         onboarding: {
            collapsed: userData.onboarding.collapsed,
            register: {
               card: userData.onboarding.register.card,
               history: userData.onboarding.register.history,
               onboarding: userData.onboarding.register.onboarding,
               promoMatch: userData.onboarding.register.promoMatch
            },
            subscribtion: {
               betDiary: userData.onboarding.subscribtion.betDiary,
               education: userData.onboarding.subscribtion.education,
               looners: userData.onboarding.subscribtion.looners,
               onboarding: userData.onboarding.subscribtion.onboarding
            }
         },
         progressBar: userData.progressBar,
         usedFreeDays: {
            discord: userData.usedFreeDays.discord,
            telegramChannel: userData.usedFreeDays.telegramChannel,
            telegramFreeChannel: userData.usedFreeDays.telegramFreeChannel,
            telegramGroup: userData.usedFreeDays.telegramGroup
         },
         telegramData: {
            en: {
               channel: {
                  avatar: userData.telegramData?.en?.channel?.avatar ?? ''
               }
            },
            ru: {
               channel: {
                  avatar: userData.telegramData?.ru?.channel?.avatar ?? ''
               }
            },
            all: {
               channel: {
                  avatar: userData.telegramData?.all?.channel?.avatar ?? ''
               },
               group: {
                  avatar: userData.telegramData?.all?.group?.avatar ?? ''
               }
            }
         },
         bonuses: userData.bonuses,
         tgProfile: userData.tgProfile
      }

      if (userData.cards.length > 0) {
         body.card = {
            id: userData.cards[0].id,
            last4: userData.cards[0].last4 ?? '',
            expMonth: userData.cards[0].expMonth ?? 0,
            expYear: userData.cards[0].expYear ?? 0
         }
      }

      user.value = body
      loading.value = false
   }

   const setLoading = (value: boolean) => {
      loading.value = value
   }

   const logout = () => {
      user.value = undefined
   }

   const changeAutoPayment = (value: boolean) => {
      if (user.value) {
         user.value.subscription.autoPayment = value
      }
   }

   const addBonus = (groupId: number) => {
      if (user.value) {
         user.value.bonuses = [...(user.value.bonuses || []), { groupId, created: new Date() }]
      }
   }

   const onboardingStatus = computed(() => {
      if (userSub.value && user.value?.onboarding.subscribtion) {
         return Object.values(user.value.onboarding.subscribtion).filter(Boolean).length
      }

      if (userNew.value && user.value?.onboarding.register) {
         return Object.values(user.value.onboarding.register).filter(Boolean).length
      }

      return 0
   })

   return {
      user,
      loading,
      setLoading,
      setUser,
      logout,
      userSub,
      userUnSub,
      userNew,
      userUnReg,
      userTrial,
      changeAutoPayment,
      onboardingStatus,
      marketingObjectLink,
      addBonus
   }
})
