<script setup lang="ts">
import { ref, type Component } from 'vue'
import { i18nRoute } from '@/i18n'
import DatalounaAI from './DatalounaAI.vue'
import Stats from './Stats.vue'
import Analytics from './Analytics.vue'
import Community from './Community.vue'
import RiskAssessment from './RiskAssessment.vue'
import { sendEvent } from '@/composables/gtm'

type TypeTabs = {
   DatalounaAI: Component
   Stats: Component
   Analytics: Component
   Community: Component
   RiskAssessment: Component
}

const tabs: TypeTabs = { DatalounaAI, Stats, Analytics, Community, RiskAssessment }

const activeTab = ref<keyof TypeTabs>()

function onTab(tab: keyof TypeTabs, type: '5steps' | 'menu') {
   activeTab.value = tab

   sendEvent(type, tab, '5steps')
}
</script>

<template>
   <article
      class="tablethd:h-404px bg-black-850 rounded-10px relative overflow-hidden pt-20px pb-16px <tablethd:py-12px"
      :class="{ bg: activeTab }"
   >
      <section v-if="activeTab === undefined" class="h-full flex flex-col px-12px">
         <header class="tablethd:pl-12px mb-20px <tablethd:mb-12px">
            <h2 class="h3-semibold">{{ $t('0g2ZBAeszZwur_33ndu0E') }}</h2>
         </header>
         <div
            class="flex-auto grid grid-cols-[376px_1fr_1fr] <tablethd:grid-cols-2 <tablet:grid-cols-1 gap-12px <tablethd:gap-8px tablethd:grid-rows-2"
         >
            <div class="block group tablethd:row-span-2" @click="onTab('DatalounaAI', '5steps')">
               <div class="flex-auto relative <tablethd:flex <tablethd:items-center">
                  <div class="tablethd:pt-24px flex gap-x-22px max-w-60%">
                     <div class="flex-shrink-0 w-2px rounded-full bg-yellow-700 shadow-yellow &group-hover:bg-orange-900 trs"></div>
                     <div class="whitespace-pre-line h2-semibold <tablethd:l-semibold">
                        {{ $t('ayr1Kpxf1so-J6wuWGyVK') }}
                     </div>
                  </div>
                  <div class="w-114px h-100px relative flex items-center text-start mt-32px ml-24px <tablethd:hidden">
                     <p class="flex flex-col ml-1px">
                        <span class="s-semibold text-center">{{ $t('dashboard.whatInside.card1.patent') }}</span>
                        <span class="m-semibold whitespace-nowrap">№ TXu-2315930</span>
                     </p>
                     <img src="/img/patent-frame.svg" alt="Patent" class="w-114px absolute bottom-0 left-1/2 -translate-x-1/2 -z-1" />
                     <span class="i-custom-patent-solid text-yellow-700 text-20px absolute -bottom-6px left-1/2 -translate-x-1/2"></span>
                  </div>

                  <i class="i-custom-lines text-253px line-gradient absolute -top-45px -right-20px tablethd:hidden"></i>
               </div>
               <div class="h-52px <tablethd:h-24px bg-black-850 relative z-1 px-24px flex items-center">
                  <button class="flex items-center gap-x-8px group">
                     <span class="text-yellow-700 &group-hover:text-orange-900 trs s-semibold <tablethd:hidden">
                        {{ $t('dashboard.whatInside.card1.more') }}
                     </span>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                  </button>
                  <i class="i-custom-lines text-253px line-gradient absolute -top-56px opacity-30 -right-28px tablethd:hidden"></i>
               </div>
               <i class="i-custom-lines text-439px absolute -top-110px -right-180px line-gradient <tablethd:hidden"></i>

               <img
                  src="/img/what-you-get/brain.png"
                  srcset="/img/what-you-get/brain@2.png 2x"
                  alt="brain"
                  class="absolute bottom-4px -right-4px <tablethd:-bottom-30px <tablethd:right-6px drop-shadow-black z-3 &group-hover:-translate-x-6px duration-400 <tablethd:w-104px"
               />
            </div>
            <div class="block group" @click="onTab('RiskAssessment', '5steps')">
               <div class="flex-auto flex flex-col justify-center relative">
                  <div class="flex gap-x-22px max-w-60%">
                     <div class="flex-shrink-0 w-2px rounded-full bg-yellow-700 shadow-yellow &group-hover:bg-orange-900 trs"></div>
                     <div class="whitespace-pre-line h3-semibold <tablethd:l-semibold">{{ $t('WwxciiFMlvBsjpm2U83sz') }}</div>
                  </div>

                  <i class="i-custom-lines text-253px line-gradient absolute -top-45px right-2px <tablethd:-right-20px"></i>
               </div>
               <div class="h-36px <tablethd:h-24px bg-black-850 relative z-1 px-24px flex items-center overflow-hidden">
                  <button class="flex items-center gap-x-8px group">
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                  </button>
                  <i class="i-custom-lines text-253px line-gradient absolute -top-56px right-1px opacity-30 <tablethd:-right-28px"></i>
               </div>

               <img
                  src="/img/what-you-get/risk.png"
                  srcset="/img/what-you-get/risk@2.png 2x"
                  alt="risk image"
                  class="absolute -top-4px right-0 <tablethd:-top-2px <tablethd:right-14px z-3 drop-shadow-black &group-hover:-translate-x-6px duration-400 <tablethd:w-88px"
               />
            </div>
            <div class="block group" @click="onTab('Stats', '5steps')">
               <div class="flex-auto flex flex-col justify-center relative">
                  <div class="flex gap-x-22px max-w-60%">
                     <div class="flex-shrink-0 w-2px rounded-full bg-yellow-700 shadow-yellow &group-hover:bg-orange-900 trs"></div>
                     <div class="whitespace-pre-line h3-semibold <tablethd:l-semibold">{{ $t('uqRUa-TQLaArZnyTGGiHY') }}</div>
                  </div>

                  <i class="i-custom-lines text-253px line-gradient absolute -top-45px right-2px <tablethd:-right-20px"></i>
               </div>
               <div class="h-36px <tablethd:h-24px bg-black-850 relative z-1 px-24px flex items-center overflow-hidden">
                  <button class="flex items-center gap-x-8px group">
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                  </button>
                  <i class="i-custom-lines text-253px line-gradient absolute -top-56px right-1px opacity-30 <tablethd:-right-28px"></i>
               </div>

               <img
                  src="/img/what-you-get/column.png"
                  srcset="/img/what-you-get/column@2.png 2x"
                  alt="column image"
                  class="w-160px absolute -top-4px right-0 <tablethd:top-0 <tablethd:right-14px z-3 drop-shadow-black &group-hover:-translate-x-6px duration-400 <tablethd:w-88px"
               />
            </div>
            <div class="block group" @click="onTab('Analytics', '5steps')">
               <div class="flex-auto flex flex-col justify-center relative">
                  <div class="flex gap-x-22px max-w-60%">
                     <div class="flex-shrink-0 w-2px rounded-full bg-yellow-700 shadow-yellow &group-hover:bg-orange-900 trs"></div>
                     <div class="whitespace-pre-line h3-semibold <tablethd:l-semibold">{{ $t('5dvdinjMuQaoxo21ruexB') }}</div>
                  </div>

                  <i class="i-custom-lines text-253px line-gradient absolute -top-45px right-2px <tablethd:-right-20px"></i>
               </div>
               <div class="h-36px <tablethd:h-24px bg-black-850 relative z-1 px-24px flex items-center overflow-hidden">
                  <button class="flex items-center gap-x-8px group">
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                  </button>
                  <i class="i-custom-lines text-253px line-gradient absolute -top-56px right-1px opacity-30 <tablethd:-right-28px"></i>
               </div>

               <img
                  src="/img/what-you-get/headphones.png"
                  srcset="/img/what-you-get/headphones@2.png 2x"
                  alt="headphones image"
                  class="w-152px absolute -top-6px -right-12px <tablethd:-top-2px <tablethd:right-12px z-3 drop-shadow-black &group-hover:-translate-x-6px duration-400 <tablethd:w-88px"
               />
            </div>
            <div class="block group" @click="onTab('Community', '5steps')">
               <div class="flex-auto flex flex-col justify-center relative">
                  <div class="flex gap-x-22px max-w-60%">
                     <div class="flex-shrink-0 w-2px rounded-full bg-yellow-700 shadow-yellow &group-hover:bg-orange-900 trs"></div>
                     <div class="whitespace-pre-line h3-semibold <tablethd:l-semibold">{{ $t('iSA86UGWAeZFqUwmTPN8Z') }}</div>
                  </div>

                  <i class="i-custom-lines text-253px line-gradient absolute -top-45px right-2px <tablethd:-right-20px"></i>
               </div>
               <div class="h-36px <tablethd:h-24px bg-black-850 relative z-1 px-24px flex items-center overflow-hidden">
                  <button class="flex items-center gap-x-8px group">
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                     <div class="w-4px h-4px rounded-full bg-yellow-700 &group-hover:bg-orange-900 trs"></div>
                  </button>
                  <i class="i-custom-lines text-253px line-gradient absolute -top-56px right-1px opacity-30 <tablethd:-right-28px"></i>
               </div>

               <picture>
                  <source
                     media="(max-width: 1023px)"
                     srcset="/img/what-you-get/community-m.png 1x, /img/what-you-get/community-m@2.png 2x"
                  />
                  <img
                     srcset="/img/what-you-get/community@2.png 2x"
                     src="/img/what-you-get/community.png"
                     alt="community image"
                     class="tablethd:w-201px absolute top-0 right-2px <tablethd:right-0 z-3 drop-shadow-black &group-hover:-translate-x-6px duration-400"
                  />
               </picture>
            </div>
         </div>
         <router-link
            :to="i18nRoute({ name: 'signout', query: { next: 'sub' } })"
            class="tablethd:hidden text-center w-full py-16px gradient-lava-btn-700 rounded-10px mt-12px l-semibold"
         >
            {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
         </router-link>
      </section>
      <section v-else class="relative z-1 h-full">
         <header
            class="tablethd:px-36px flex flex-wrap gap-x-24px gap-y-20px items-center justify-between relative overflow-hidden mb-40px <tablethd:mb-20px"
         >
            <button
               class="text-yellow-700 flex items-center gap-x-16px &hover:text-orange-900 transition-color duration-200 <tablethd:hidden"
               @click="activeTab = undefined"
            >
               <span class="i-custom-long-arrow-outline text-24px rotate-180"></span>
               <span class="s-semibold">{{ $t('dashboard.whatInside.sliderNavigation.back') }}</span>
            </button>
            <div class="tablethd:hidden flex justify-between gap-x-12px w-full <tablethd:px-12px">
               <h2 class="h3-semibold max-w-80%">{{ $t('0g2ZBAeszZwur_33ndu0E') }}</h2>
               <button class="i-custom-cancel-outline text-12px mt-8px" @click="activeTab = undefined"></button>
            </div>
            <nav class="overflow-hidden select-none">
               <ul
                  class="flex gap-x-20px <tablethd:gap-x-16px s-semibold overflow-x-auto no-scrollbar snap snap-x snap-mandatory <tablethd:l-semibold <tablethd:px-12px <tablethd:scroll-px-12px"
               >
                  <li
                     class="text-gray-800 flex-shrink-0 <laptop:snap-start"
                     :class="
                        activeTab === 'DatalounaAI'
                           ? 'text-yellow-700'
                           : '&hover:text-orange-900 cursor-pointer transition-color duration-200'
                     "
                     @click="onTab('DatalounaAI', 'menu')"
                  >
                     {{ $t('xRvTZe5iNYjhHxg8UQ_pY') }}
                  </li>
                  <li
                     class="text-gray-800 flex-shrink-0 <laptop:snap-start"
                     :class="
                        activeTab === 'RiskAssessment'
                           ? 'text-yellow-700'
                           : '&hover:text-orange-900 cursor-pointer transition-color duration-200'
                     "
                     @click="onTab('RiskAssessment', 'menu')"
                  >
                     {{ $t('WwxciiFMlvBsjpm2U83sz') }}
                  </li>
                  <li
                     class="text-gray-800 flex-shrink-0 <laptop:snap-start"
                     :class="
                        activeTab === 'Analytics'
                           ? 'text-yellow-700'
                           : '&hover:text-orange-900 cursor-pointer transition-color duration-200'
                     "
                     @click="onTab('Analytics', 'menu')"
                  >
                     {{ $t('8mKupwNVl6TzQMaV8ZV6V') }}
                  </li>
                  <li
                     class="text-gray-800 flex-shrink-0 <laptop:snap-start"
                     :class="
                        activeTab === 'Stats' ? 'text-yellow-700' : '&hover:text-orange-900 cursor-pointer transition-color duration-200'
                     "
                     @click="onTab('Stats', 'menu')"
                  >
                     {{ $t('uqRUa-TQLaArZnyTGGiHY') }}
                  </li>
                  <li
                     class="text-gray-800 flex-shrink-0 <laptop:snap-start"
                     :class="
                        activeTab === 'Community'
                           ? 'text-yellow-700'
                           : '&hover:text-orange-900 cursor-pointer transition-color duration-200'
                     "
                     @click="onTab('Community', 'menu')"
                  >
                     {{ $t('P1tNUaQ0iH0ZlUf_l_RwI') }}
                  </li>
               </ul>
            </nav>
         </header>
         <component :is="tabs[activeTab]" class="relative z-1" />

         <i class="i-custom-lines text-710px text-brown-700/40 absolute -top-164px right-46px -z-1 <tablethd:hidden"></i>
      </section>
   </article>
</template>

<style scoped>
.block {
   --uno: 'bg-black-800 rounded-10px relative overflow-hidden z-1 flex flex-col shadow-black cursor-pointer';
   --uno: '<tablethd:h-86px';
}
.block::before {
   content: '';
   --uno: 'absolute inset-0 rounded-10px z-2';
   background: radial-gradient(77% 64% at 111% 107%, rgba(222, 58, 30, 0.2) 0%, rgba(30, 31, 37, 0) 100%);
}

.line-gradient {
   background: linear-gradient(204deg, #4d505f 0%, rgba(54, 56, 67, 0) 100%);
}

@screen tablethd {
   .bg::after,
   .bg::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
   }

   .bg::after {
      background-image: url('/img/what-you-get/datalounaAI/triangles.svg');
      background-repeat: no-repeat;
      background-size: 442px;
      background-position: -144px 0;
   }
   .bg::before {
      background-image: radial-gradient(87.39% 79% at 10% 112%, rgba(222, 58, 30, 0.04) 0%, rgba(30, 31, 37, 0) 100%);
      border-radius: 10px;
   }
}
</style>
