<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { locale, i18nRoute } from '@/i18n'
import { useUserStore } from '@/stores'
import { changeOnboardingCollapseStatusRequest } from '@/composables'
import Modal from '@/components/UI/Modal.vue'
import SecretStrategy from '@/components/SecretStrategy.vue'
import { sendEvent } from '@/composables/gtm'
import { useGoToMainChannel } from '@/composables/get-tg-link'
import Spinner from '@/components/UI/Spinner.vue'

const router = useRouter()

const userStore = useUserStore()
const changeOnboardingCollapseStatus = changeOnboardingCollapseStatusRequest()
const { getLink, loadingGetLink } = useGoToMainChannel()

const modalSecretIsActive = ref(false)

function onGoToClosedChat(text: string) {
   sendEvent('cta', text)
   getLink()
}
</script>

<template>
   <div class="flex flex-col gap-y-20px w-full overflow-hidden">
      <div class="h-40px px-24px <tablet:px-12px <laptop:mx-16px bg-black-850 rounded-10px flex items-center gap-x-8px h3-semibold">
         <button
            class="h-20px px-12px border border-black-700 rounded-full flex items-center &hover:border-orange-900 group transition-border duration-200"
            @click="changeOnboardingCollapseStatus.set(true)"
         >
            <i class="i-custom-arrow-outline text-12px rotate-90 mr-1px &group-hover:text-orange-900 transition-color duration-200"></i>
         </button>
         {{ $t('kawLe8E3byKeaUitmS2iE') }}
      </div>
      <div class="wb2-container">
         <div class="wb2-item">
            <div class="wb2-item-description">
               <div class="wb-item-title">
                  {{ $t('Kf8RzlAGkZljNJKZjWX2N') }} <span class="text-yellow-700">{{ $t('emd_eM9xr85-W6KMPZkyA') }}</span>
                  <br />
                  {{ $t('FOFNylUEQ3AkfKYqzZx3F') }} <span class="text-yellow-700">{{ $t('v7BS7p_LfF82jDd7XtqgK') }}</span>
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('NthQPU0rqreCDIHxzXEna') }} <span class="text-yellow-700">{{ $t('q6ErqMd0Ku4Nu-cThCQtr') }}</span> —
                  <br />
                  {{ $t('r9bGpCxNNMp9T28NnUdQR') }} <span class="text-yellow-700">{{ $t('dlKsUWLJCtXbbPA_RYeGe') }}</span>
               </div>
            </div>

            <img
               src="/img/where-begin2/s1-1.png"
               srcset="/img/where-begin2/s1-1@2.png 2x"
               alt="s1-1"
               class="absolute left-20px bottom-12px -z-1"
            />

            <picture>
               <source media="(max-width: 1023px)" srcset="/img/where-begin2/s1-2m.png 1x, /img/where-begin2/s1-2m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s1-2@2.png 2x"
                  src="/img/where-begin2/s1-2.png"
                  alt="s1-2"
                  class="absolute -right-10px <fullhd:-right-24px <mac:-right-28px <tablethd:-right-14px top-70px -z-2"
               />
            </picture>
         </div>
         <div class="wb2-item justify-end">
            <div class="wb2-item-description mb-40px <laptop:mb-8px">
               <div class="wb-item-title">
                  {{ $t('cqw-2-IC09a2cF8NSQoEB') }}
                  <br />
                  <span class="text-yellow-700">{{ $t('vSJEbAL9wavw2le1HgHI5') }}</span> {{ $t('c2wdRYM3krbqu7xUHLupd') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('0RgoCOCaVk2abO0g8FemA') }}
                  <br />
                  {{ $t('ME6zi0Fu9p_riD8cAZ85D') }} <span class="text-yellow-700">{{ $t('VsrSB0eXD2gw8Jw9NH4qN') }}</span>
               </div>
            </div>

            <div
               class="w-232px bg-gray-50/4 backdrop-blur-25 border border-gray-50/2 rounded-full px-8px py-1px text-green-500 absolute top-16px left-1/2 -translate-x-1/2 flexc gap-x-8px"
            >
               <i class="i-custom-check-outline text-8px"></i>
               <span class="s-semibold">{{ $t('Vy0LRpHrlybn0XJ9ky6SM') }}</span>
            </div>

            <div class="absolute left-1/2 -translate-x-1/2 top-36px -z-1 w-full h-full max-w-272px max-h-124px">
               <img src="/src/assets/img/where-begin/s21.png" srcset="/src/assets/img/where-begin/s21@2.png 2x" alt="s21" />
               <img
                  srcset="/img/where-begin2/s2-2@2.png 2x"
                  src="/img/where-begin2/s2-2.png"
                  alt="s2-2"
                  class="absolute -right-109px -bottom-142px <tablethd:-bottom-111px -z-2"
               />
            </div>

            <img
               srcset="/img/where-begin2/s2-3@2.png 2x"
               src="/img/where-begin2/s2-3.png"
               alt="s2-3"
               class="absolute -left-20px top-70px -z-2"
            />
         </div>
         <div class="wb2-item">
            <div class="wb2-item-description">
               <div class="wb-item-title">
                  {{ $t('6_AlRcGWavTFzs-5zPcbI') }}
                  <br />
                  {{ $t('LXF7yVHGpEjU6zLbPXUYa') }} <span class="text-yellow-700">{{ $t('eUwI1Kli8S2MTmaM19jqH') }}</span>
                  {{ $t('A2EogWEqwZkrcEKAa9PK6') }}
                  <span class="text-yellow-700">{{ $t('Yvzm6RTVSv4TTct3GQXVM') }}</span>
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('a_YLrQ3b50IBL0kSsxbsy') }} <span class="text-yellow-700">{{ $t('7MTp9LYnUXxFtVJr9g05n') }}</span
                  >{{ $t('pgWYW12fWZxHg8bk_4aVZ') }}
                  <br />
                  {{ $t('EOWd3G_MFI5OFXDGzAXcJ') }} <span class="text-yellow-700">{{ $t('On_uXPai--Lxv3WzO9x-j') }}</span>
               </div>
            </div>

            <img
               src="/img/where-begin2/s3-1.png"
               srcset="/img/where-begin2/s3-1@2.png 2x"
               alt="s3-1"
               class="absolute right-0 bottom-0px -z-1"
            />
            <img
               srcset="/img/where-begin2/s3-2@2.png 2x"
               src="/img/where-begin2/s3-2.png"
               alt="s3-2"
               class="absolute top-70px -right-12px -z-2"
            />
            <img
               srcset="/img/where-begin2/s3-3@2.png 2x"
               src="/img/where-begin2/s3-3.png"
               alt="s3-3"
               class="absolute bottom-58px -left-20px -z-2"
            />
         </div>
         <div v-if="userStore.user?.progressBar.freeDays" class="wb2-item wb2-glow justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  <span class="text-green-500">{{ $t('OTs_sH7STe5MPYD9E-9GH') }}</span>
                  {{ $t('Iyg3OfVAkz0ccTMBXZzzD') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('HxCi-UsUjwJj1ThaEfY_E') }}
                  <span class="text-green-500">{{ $t('_VN3mUPZUaOnytbiVdV8V') }}</span>
                  {{ $t('t23Q-JrGthQn-i-960o4i') }}
               </div>
            </div>

            <img
               src="/img/where-begin2/s4-bonus.png"
               srcset="/img/where-begin2/s4-bonus@2.png 2x"
               alt="s4-bonus"
               class="w-204px absolute top-0 right-0"
            />

            <img
               src="/img/where-begin2/track-green.png"
               srcset="/img/where-begin2/track-green@2.png 2x"
               alt="track-green"
               class="absolute -left-24px top-70px -z-1"
            />

            <router-link
               :to="i18nRoute({ name: 'user' })"
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px block text-center"
               @click="
                  sendEvent(
                     'cta',
                     $t('3osaAYgKRMnIz955Y3-kl', {}, { locale: 'en' }),
                     undefined,
                     undefined,
                     router,
                     i18nRoute({ name: 'user' })
                  )
               "
            >
               {{ $t('3osaAYgKRMnIz955Y3-kl') }}
            </router-link>
         </div>
         <div v-else-if="userStore.user?.progressBar.isErrorPayment" class="wb2-item wb2-glow-red justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  <span class="text-red-700">{{ $t('Hu_7Ovix7Ui687NJeCmu-') }}</span>
                  {{ $t('KNQS-PWmO1CxN2_kxeFoM') }}
                  <span class="text-red-700"> {{ $t('0yiEcTOQMuZpqt8zEaRj_') }}</span>
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  <span class="text-red-700">{{ $t('ggICZEKhravXtDxo8-Efm') }}</span> {{ $t('fCBmsRifjpVh9AaX-aSDe') }}
               </div>
            </div>

            <img
               src="/img/where-begin2/s4-payment.png"
               srcset="/img/where-begin2/s4-payment@2.png 2x"
               alt="s4-s4-payment"
               class="w-204px absolute top-0 right-0"
            />

            <img
               src="/img/where-begin2/track-red.png"
               srcset="/img/where-begin2/track-red@2.png 2x"
               alt="track-red"
               class="absolute -left-24px top-70px -z-1"
            />

            <router-link
               :to="i18nRoute({ name: 'subscription' })"
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px block text-center"
               @click="
                  sendEvent(
                     'cta',
                     $t('15zw7Qf7FOFOI6r2O4T3-', {}, { locale: 'en' }),
                     undefined,
                     undefined,
                     router,
                     i18nRoute({ name: 'subscription' })
                  )
               "
            >
               {{ $t('15zw7Qf7FOFOI6r2O4T3-') }}
            </router-link>
         </div>
         <div v-else-if="userStore.userUnSub" class="wb2-item wb2-glow-red justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  {{ $t('vGFygtlUqRAMdceoZ7AwD') }}
                  <span class="text-red-700"> {{ $t('Z3VZKuq0YF4hpFBddPdhx') }}</span>
                  {{ $t('33LFp_l41DBKnTDuWNzBO') }}
                  {{ dayjs(userStore.user?.subscription.endDate).locale(locale).format('D.MM.YYYY') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('8T7ei8jWd38_W6RP4Neeg') }}
                  <span class="text-red-700">{{ $t('rF1CuYSGdmzCEbiqUBqmp') }}</span>
                  {{ $t('_WOe4Ou7ra0DoiFNKLmUV') }}
               </div>
            </div>

            <img
               v-if="userStore.user?.subscription.planType === 'ONE_TIME'"
               src="/img/where-begin2/s4-unsub-one.png"
               srcset="/img/where-begin2/s4-unsub-one@2.png 2x"
               alt="s4-unsub-one"
               class="w-204px absolute top-0 right-0"
            />
            <img
               v-else
               src="/img/where-begin2/s4-unsub-rec.png"
               srcset="/img/where-begin2/s4-unsub-rec@2.png 2x"
               alt="s4-unsub-rec"
               class="w-204px absolute top-0 right-0"
            />

            <img
               src="/img/where-begin2/track-red.png"
               srcset="/img/where-begin2/track-red@2.png 2x"
               alt="track-red"
               class="absolute -left-24px top-70px -z-1"
            />

            <router-link
               :to="i18nRoute({ name: 'subscription' })"
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px block text-center"
               @click="
                  sendEvent(
                     'cta',
                     $t('DPjMQuPpbBK8k2nuNSvDi', {}, { locale: 'en' }),
                     undefined,
                     undefined,
                     router,
                     i18nRoute({ name: 'subscription' })
                  )
               "
            >
               {{ $t('DPjMQuPpbBK8k2nuNSvDi') }}
            </router-link>
         </div>
         <div v-else-if="userStore.userNew" class="wb2-item wb2-glow justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  {{ $t('q0fhIAhtbdExcqLB5vG3l') }}
                  <span class="text-green-500">{{ $t('i0ILxIoY4GvkYoDUgd8uN') }}</span>
                  {{ $t('rAZodDzScSGbJ8GRHkViU') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  <span class="text-green-500">{{ $t('8WNTj_0U2BiZw4rmMfS8U') }}</span>
                  {{ $t('wyMeOEtOnnW37BsVyCQsB') }}
               </div>
            </div>

            <img
               src="/img/where-begin2/s4.png"
               srcset="/img/where-begin2/s4@2.png 2x"
               alt="s4-reg"
               class="w-204px absolute top-0 right-0"
            />

            <img
               src="/img/where-begin2/track-green.png"
               srcset="/img/where-begin2/track-green@2.png 2x"
               alt="track-green"
               class="absolute -left-24px top-70px -z-1"
            />

            <router-link
               :to="i18nRoute({ name: 'subscription' })"
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px block text-center"
               @click="
                  sendEvent(
                     'cta',
                     $t('A-7ajtGZn1R9VxVKJN6Me', {}, { locale: 'en' }),
                     undefined,
                     undefined,
                     router,
                     i18nRoute({ name: 'subscription' })
                  )
               "
            >
               {{ $t('A-7ajtGZn1R9VxVKJN6Me') }}
            </router-link>
         </div>
         <div v-else class="wb2-item wb2-glow justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  <span class="text-green-500">{{ $t('TINalT1jJxBgG8XKSy3sN') }}</span>
                  {{ $t('enDnZ3oUJtJwC9cf61eA7') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('y2sXfKruBNqwdokW9cCHe') }}
                  <span class="text-green-500">{{ $t('9TwyMmysLsUzeT3yhahaL') }}</span>
               </div>
            </div>

            <img
               src="/img/where-begin2/s4-trial.png"
               srcset="/img/where-begin2/s4-trial@2.png 2x"
               alt="s4-trial"
               class="w-204px absolute -top-1px right-0"
            />

            <img
               src="/img/where-begin2/track-green.png"
               srcset="/img/where-begin2/track-green@2.png 2x"
               alt="track-green"
               class="absolute -left-24px top-70px -z-1"
            />

            <button
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px block text-center"
               @click="onGoToClosedChat($t('7jLW6Y6MoHh98jdxLkYES', {}, { locale: 'en' }))"
            >
               <span :class="{ invisible: loadingGetLink }"> {{ $t('7jLW6Y6MoHh98jdxLkYES') }}</span>
               <div v-if="loadingGetLink" class="absolute top-50% left-50% -translate-1/2">
                  <Spinner class="w-1em h-1em" />
               </div>
            </button>
         </div>
      </div>
   </div>

   <Modal v-model="modalSecretIsActive">
      <SecretStrategy />
   </Modal>
</template>

<style scoped>
.wb2-container {
   --uno: 'grid grid-cols-4 gap-x-8px <laptop:px-16px';
}
.wb2-item {
   --bg-x: 0%;
   --bg-y: 0%;
   --uno: 'h-360px relative py-36px px-24px z-1 rounded-10px overflow-hidden flex flex-col';

   background: radial-gradient(134.63% 141.42% at var(--bg-x) var(--bg-y), rgba(71, 73, 94, 0.2) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}
.wb2-item:nth-child(2) {
   --bg-x: 0%;
   --bg-y: 100%;
}
.wb2-item-description {
   --uno: 'flex flex-col gap-y-8px';
}
.wb-item-title {
   --uno: 'h3-semibold';
}
.wb-item-line {
   --uno: 'w-20px h-2px rounded-full bg-gray-800';
}
.wb-item-text {
   --uno: 's-medium2';
}
.wb2-glow {
   background: radial-gradient(116% 90% at 0% 100%, rgba(44, 194, 86, 0.12) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}
.wb2-glow-red {
   background: radial-gradient(116% 90% at 0% 100%, rgba(223, 28, 72, 0.1) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}

@screen lt-fullhd {
   .wb2-item {
      --uno: 'px-20px';
   }
}

@screen lt-tablethd {
   .wb2-container {
      --uno: 'snap-x snap-mandatory scroll-p-16px w-full flex overflow-x-auto';
      -ms-overflow-style: none;
      scrollbar-width: none;
   }
   .wb2-container::-webkit-scrollbar {
      display: none;
   }

   .wb2-item {
      --uno: 'w-304px h-328px flex-shrink-0 snap-start px-24px';
   }
}
</style>
