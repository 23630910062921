/* eslint-disable unicorn/no-document-cookie */
function parseCookie() {
   try {
      if (document === undefined || !document.cookie) {
         return {}
      }

      return document.cookie
         .split(';')
         .map((v) => v.split('='))
         .reduce(
            (accumulator, v) => {
               accumulator[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
               return accumulator
            },
            {} as Record<string, string>
         )
   } catch (error) {
      console.log('Error parsing cookie', error)

      return {}
   }
}

export function getCookie(name: string) {
   return parseCookie()[name]
}

export function getAllCookies() {
   return parseCookie()
}

export function setCookie(name: string, value: string, days: number) {
   const now = new Date()
   const time = now.getTime()
   const expireTime = time + 1000 * 3600 * 24 * days
   now.setTime(expireTime)

   try {
      if (typeof document === 'undefined') {
         return
      }

      const cookieDomain = window.location.hostname.split('.').slice(-2).join('.')

      document.cookie = `${encodeURIComponent(name)}=` + `;expires=Thu, 01 Jan 1970 00:00:00 GMT` + `;path=/` + `;domain=${cookieDomain}`

      document.cookie =
         `${encodeURIComponent(name)}=${encodeURIComponent(value)}` +
         `;expires=${now.toUTCString()}` +
         `;path=/` +
         `;domain=${cookieDomain}`
   } catch (error) {
      console.log('Error setting cookie', error)
   }
}
