import { computed } from 'vue'
import { useLazyQuery } from '@vue/apollo-composable'
import { getLinkForTelegramLink, unlinkTelegramUser, getChatLink } from '@/gql/user'
import { goToLink } from '@/composables'
import { useUserStore, useGlobalStore } from '@/stores'
import { i18n } from '@/i18n'
export const mainChannelGroup = 3

export function sendUnknownError() {
   const globalStore = useGlobalStore()
   const t = i18n.global.t
   globalStore.notificationAdd({
      type: 'error',
      text: t('2B_D-BRITef-paafaJHyQ')
   })
}

export function useTelegramLink() {
   const { load, loading, restart, onResult, onError } = useLazyQuery(getLinkForTelegramLink)

   const onGetLinkForTelegramLink = (groupId?: number) => {
      load(undefined, {
         channelGroup: groupId
      }) || restart()
   }

   onResult((result) => {
      if (result.loading) return

      if (result.data?.getLinkForTelegramLink?.success) {
         goToLink(result.data.getLinkForTelegramLink.url)
      }
   })

   onError(() => {
      sendUnknownError()
   })

   return {
      onGetLinkForTelegramLink,
      loading
   }
}

export function useUnlinkTelegramUser() {
   const { load, loading, restart, onResult, onError } = useLazyQuery(unlinkTelegramUser)
   const userStore = useUserStore()

   const onUnlinkTelegramUser = () => {
      load() || restart()
   }

   onResult((result) => {
      if (result.loading) return

      if (result.data.unlinkTelegramUser.success && userStore.user) {
         userStore.user.tgProfile = undefined
      }
   })

   onError(() => {
      sendUnknownError()
   })

   return {
      onUnlinkTelegramUser,
      loading
   }
}

export function useGetChatLink() {
   const { load, loading, restart, onResult, onError } = useLazyQuery(getChatLink)

   const onGetChatLink = (groupId: number) => {
      load(undefined, {
         channelGroup: groupId
      }) || restart()
   }

   onResult((result) => {
      if (result.loading) return

      if (result.data.getChatLink.success) {
         goToLink(result.data.getChatLink.url)
      }
   })

   onError(() => {
      sendUnknownError()
   })

   return {
      onGetChatLink,
      loadingChatLink: loading
   }
}

export function useGoToMainChannel() {
   const { load, loading, restart, onResult, onError } = useLazyQuery(getLinkForTelegramLink)
   const {
      load: loadChatLink,
      loading: loadingChatLink,
      restart: restartChatLink,
      onResult: onResultChatLink,
      onError: onErrorChatLink
   } = useLazyQuery(getChatLink)

   const userStore = useUserStore()

   const getLink = () => {
      if (!userStore.user) return

      if (userStore.user.tgProfile) {
         loadChatLink(undefined, {
            channelGroup: mainChannelGroup
         }) || restartChatLink()
      } else {
         load(undefined, {
            channelGroup: mainChannelGroup
         }) || restart()
      }
   }

   onResult((result) => {
      if (result.loading) return

      if (result.data?.getLinkForTelegramLink?.success) {
         goToLink(result.data.getLinkForTelegramLink.url)
      }
   })

   onResultChatLink((result) => {
      if (result.loading) return

      if (result.data.getChatLink.success) {
         goToLink(result.data.getChatLink.url)
      }
   })

   onError(() => {
      sendUnknownError()
   })

   onErrorChatLink(() => {
      sendUnknownError()
   })

   return {
      getLink,
      loadingGetLink: computed(() => loading.value || loadingChatLink.value)
   }
}
