import { graphql } from './generate'

export const getThirtyDaysGift = graphql(`
   query GetThirtyDaysGift {
      getThirtyDaysGift {
         success
         reason
      }
   }
`)

export const getFreeDay = graphql(`
   query GetFreeDay($type: FreeDaysType!) {
      getFreeDay(type: $type) {
         success
         reason
      }
   }
`)

export const getTelegramLink = graphql(`
   query GetTelegramLink($type: UserTelegramType!, $locale: UserTelegramLanguage) {
      getTelegramInviteLink(type: $type, locale: $locale) {
         inviteLink
      }
   }
`)

export const deleteUser = graphql(`
   mutation DeleteUser($code: String!) {
      deleteUser(code: $code) {
         success
      }
   }
`)

export const getBonus = graphql(`
   query GetBonus($channelGroup: Float!) {
      getBonus(channelGroup: $channelGroup) {
         success
         inviteLink
         groupId
      }
   }
`)

export const unlinkTelegramUser = graphql(`
   query UnlinkTelegramUser {
      unlinkTelegramUser {
         success
      }
   }
`)

export const getLinkForTelegramLink = graphql(`
   query GetLinkForTelegramLink($channelGroup: Float) {
      getLinkForTelegramLink(channelGroup: $channelGroup) {
         success
         url
         code
      }
   }
`)

export const getChatLink = graphql(`
   query GetChatLink($channelGroup: Float!) {
      getChatLink(channelGroup: $channelGroup) {
         success
         url
      }
   }
`)

export const giveAnalyticInfo = graphql(`
   query GiveAnalyticInfo {
      giveAnalyticInfo {
         success
      }
   }
`)
